import React from 'react';

import { Tooltip } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../../../helpers/TranslationHelper';

const MULTIPLE_OBJECTS = 2;

interface IOwnProps {
    list: string[];
}

const ListWithHoverCellRenderer = ({ list }: IOwnProps) => {
    const classes = useStyles();

    const stringValue = list.join(', ');
    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <>
                {list.length > MULTIPLE_OBJECTS && (
                    <Tooltip title={TranslationHelper.translate(stringValue)}>
                        <div className={classes.icon} data-testid="icon">
                            <InfoIcon />
                        </div>
                    </Tooltip>
                )}
            </>
            <div className={classes.cellValue}>{stringValue}</div>
        </div>
    );
};

export default ListWithHoverCellRenderer;
