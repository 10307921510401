import { connect } from 'react-redux';
import { resetLevel } from '../../../../state/_actions';
import { getRoles, getUsers } from '../../../../state/app/collections';
import { enterCreateMode, selectUser } from '../../../../state/ui/usersSlice';
import { TRootState } from '../../../../store';
import {
    getPreviewPaneMonitoredId,
    getUsersSourceSetModel,
} from '../../../discovery/selectors';
import { getRolesData, getUsersAsSourceSet } from '../../selectors';

const mapStateToProps = (state: TRootState) => ({
    sourceSet: getUsersAsSourceSet(state),
    rolesData: getRolesData(state),
    selectedSourceSetElementId: getPreviewPaneMonitoredId(state),
    sourceSetModel: getUsersSourceSetModel(state),
});

const mapDispatchToProps = {
    resetLevel,
    getUsers,
    getRoles,
    selectUser,
    enterCreateMode,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
