import React, { Component, ComponentType } from 'react';

import { Link } from 'react-router-dom';

import { compose } from 'redux';

import { Field, Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from '../../Themable.hoc';

import { ICustomer } from '../../../../state/login/types';
import { IDictionaryItem } from '../../../../state/app/dictionaries';

import { IRestPostPasswordReset } from '../../../../services/auth';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import { passwordResetMailSchema } from '../../../../schemas';

import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';

interface IOwnProps {
    onSubmit: (
        password: IRestPostPasswordReset,
        onSuccess: () => void,
        onFailure: (error: any) => void
    ) => void;
    customers: ICustomer[];
}

type TProps = TThemableProps & IOwnProps & TConnectableProps;

interface IPasswordResetForm {
    login: string;
    email: string;
    tenant: string;
}

interface IState {
    customers: any;
    blankForm: IPasswordResetForm;
    serverError?: string;
    fetching: boolean;
    emailSent: boolean;
}
const mapDictionaryItems = (items: IDictionaryItem[], hasEmpty?: boolean) => {
    const initial = hasEmpty
        ? [
              <MenuItem key={'-1'} value={-1}>
                  {''}
              </MenuItem>,
          ]
        : [];
    return initial.concat(
        items.map((item: IDictionaryItem) => (
            <MenuItem key={item.id} value={item.id}>
                {item.name}
            </MenuItem>
        ))
    );
};

class SendEmailForm extends Component<TProps> {
    public state: IState = {
        customers: this.props.customers,
        blankForm: {
            login: '',
            email: '',
            tenant: this.props.customers[0].id,
        },
        serverError: undefined,
        fetching: false,
        emailSent: false,
    };

    public render() {
        const { customers, blankForm, fetching, serverError, emailSent } =
            this.state;
        const displayCustomers = customers && customers.length > 1;

        const { classes } = this.props;

        return (
            <Formik
                enableReinitialize={true}
                initialValues={blankForm}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.handleValues}
                validationSchema={passwordResetMailSchema()}
            >
                {({ submitForm, errors, values, setFieldValue }) => (
                    <Form>
                        <div className={classes.title}>
                            {TranslationHelper.translate(
                                'Password change request'
                            )}
                        </div>
                        {!emailSent && (
                            <>
                                <div className={classes.subtitle}>
                                    {TranslationHelper.translate(
                                        'Enter login or e-mail'
                                    )}
                                </div>
                                <Field
                                    id="login-id"
                                    error={
                                        !!(
                                            (errors && errors.login) ||
                                            (errors as any).loginOrEmail
                                        )
                                    }
                                    helperText={
                                        (errors && errors.login) ||
                                        (errors as any).loginOrEmail
                                    }
                                    name={'login'}
                                    label={TranslationHelper.translate('Login')}
                                    type={'text'}
                                    fullWidth={true}
                                    component={FieldWrapper}
                                    className={classes.formField}
                                    margin="dense"
                                    variant="outlined"
                                    disabled={!!values.email}
                                />
                                <Field
                                    id="email-id"
                                    error={
                                        !!(
                                            (errors && errors.email) ||
                                            (errors as any).loginOrEmail
                                        )
                                    }
                                    helperText={
                                        (errors && errors.email) ||
                                        (errors as any).loginOrEmail
                                    }
                                    name={'email'}
                                    label={TranslationHelper.translate(
                                        'E-mail'
                                    )}
                                    type={'email'}
                                    fullWidth={true}
                                    component={FieldWrapper}
                                    className={classes.formField}
                                    margin="dense"
                                    variant="outlined"
                                    disabled={!!values.login}
                                />
                                {displayCustomers && (
                                    <Field
                                        id="tenant-id"
                                        error={!!(errors && errors.tenant)}
                                        helperText={errors && errors.tenant}
                                        name={'tenant'}
                                        label={TranslationHelper.translate(
                                            'Data source'
                                        )}
                                        select={true}
                                        fullWidth={true}
                                        required={true}
                                        component={FieldWrapper}
                                        className={classes.formField}
                                        margin="dense"
                                        variant="outlined"
                                    >
                                        {mapDictionaryItems(customers)}
                                    </Field>
                                )}
                            </>
                        )}

                        {emailSent && (
                            <>
                                <FormControl>
                                    <FormHelperText id="component-error-text">
                                        {TranslationHelper.translate(
                                            'Password change request has been sent'
                                        )}
                                    </FormHelperText>
                                </FormControl>
                            </>
                        )}

                        <div className={classes.formButtons}>
                            <Link to={'/login'}>
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    role="back"
                                >
                                    {TranslationHelper.translate('Back')}
                                </Button>
                            </Link>
                            {!emailSent && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={fetching}
                                    role="send"
                                >
                                    {TranslationHelper.translate('Send e-mail')}
                                </Button>
                            )}
                        </div>
                        {serverError && (
                            <FormControl error={true}>
                                <FormHelperText id="component-error-text">
                                    {serverError}
                                </FormHelperText>
                            </FormControl>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }

    private handleValues = ({ login, email, tenant }: IPasswordResetForm) => {
        const { onSubmit } = this.props;
        this.setState({ fetching: true, serverError: undefined });

        const resetParams = () => {
            return email
                ? {
                      email,
                      tenant: tenant || this.state.customers[0].id,
                  }
                : {
                      login,
                      tenant: tenant || this.state.customers[0].id,
                  };
        };

        onSubmit(
            resetParams(),
            () => {
                this.setState({ emailSent: true, fetching: false });
            },
            (error: any) => {
                if (error.status !== 200) {
                    this.setState({
                        serverError: TranslationHelper.translate(
                            'Internal server error. Please contact with administrator.'
                        ),
                    });
                }
                this.setState({ fetching: false });
            }
        );
    };
}

export default compose(
    Themable,
    Connectable
)(SendEmailForm) as ComponentType<IOwnProps>;
