import React from 'react';

import SaveIcon from '@material-ui/icons/Save';
import { default as PasswordIcon } from '@material-ui/icons/Lock';
import { default as EditIcon } from '@material-ui/icons/Edit';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

import {
    enterEditMode,
    enterEditPasswordMode,
} from '../../../../../../state/ui/usersSlice';
import { deleteUser } from '../../../../../../state/app/collections';

import { useAppDispatch } from '../../../../../../hooks';
import useToggle from '../../../../../../hooks/useToggle';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import DeleteButton from '../../../../../../components/DeleteButton';
import PaneHeader from '../../../../../../components/PaneHeader';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import AssignRightsDialog from '../AssignRightsDialog';

interface IOwnProps {
    userId?: number | null;
    title: string;
    userDataRightEnabled?: boolean;
    editionEnabled?: boolean;
    deletionEnabled?: boolean;
    passwordEditionEnabled?: boolean;
    onClose?: () => void;
    onSave?: () => void;
}

const UserPaneHeader = ({
    userId,
    title,
    userDataRightEnabled,
    editionEnabled,
    deletionEnabled,
    passwordEditionEnabled,
    onClose,
    onSave,
}: IOwnProps) => {
    const { isOpen, handleToggle } = useToggle();

    const dispatch = useAppDispatch();

    const deleteUserHandler = () => {
        if (userId) {
            dispatch(deleteUser(userId));
        }
    };
    const handleCloseClick = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleEditClick = () => {
        dispatch(enterEditMode());
    };

    const handleEditPasswordClick = () => {
        dispatch(enterEditPasswordMode());
    };

    return (
        <>
            <PaneHeader
                title={title}
                onCloseClick={handleCloseClick}
                renderRightCustomControls={() => (
                    <>
                        {userId && (
                            <>
                                {userDataRightEnabled && (
                                    <IconButtonWithTooltip
                                        title={TranslationHelper.translate(
                                            'Data access rights'
                                        )}
                                        onClick={handleToggle}
                                    >
                                        <CategoryOutlinedIcon />
                                    </IconButtonWithTooltip>
                                )}
                                {passwordEditionEnabled && (
                                    <IconButtonWithTooltip
                                        title={TranslationHelper.translate(
                                            'Change password'
                                        )}
                                        onClick={handleEditPasswordClick}
                                    >
                                        <PasswordIcon />
                                    </IconButtonWithTooltip>
                                )}
                                {editionEnabled && (
                                    <IconButtonWithTooltip
                                        title={TranslationHelper.translate(
                                            'Edit user'
                                        )}
                                        onClick={handleEditClick}
                                    >
                                        <EditIcon />
                                    </IconButtonWithTooltip>
                                )}
                                {deletionEnabled && (
                                    <DeleteButton
                                        title="User removal confirmation"
                                        tooltip="Delete user"
                                        message="Do you really want to DELETE this user?"
                                        deleteMethod={deleteUserHandler}
                                    />
                                )}
                                {isOpen && (
                                    <AssignRightsDialog
                                        isOpen={isOpen}
                                        closeDialog={handleToggle}
                                        userId={userId}
                                    />
                                )}
                            </>
                        )}
                        {onSave && (
                            <IconButtonWithTooltip
                                title={TranslationHelper.translate('Save')}
                                onClick={onSave}
                            >
                                <SaveIcon />
                            </IconButtonWithTooltip>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default UserPaneHeader;
