import { connect } from 'react-redux';

import { forgetRequest } from '../../state/app/sync';

import {
    deselectUser,
    enterEditMode,
    enterEditPasswordMode,
    goToPreviewMode,
    resetMode,
} from '../../state/ui/usersSlice';

import { createUser } from '../../state/app/collections';

import { TRootState } from '../../store';

import { getRolesAsArray, getUser } from '../../pages/users/selectors';

const mapStateToProps = (state: TRootState) => ({
    user: getUser(state),
    roles: getRolesAsArray(state),
});

const mapDispatchToProps = {
    forgetRequest,
    resetMode,
    createUser,
    deselectUser,
    enterEditMode,
    enterEditPasswordMode,
    goToPreviewMode,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
