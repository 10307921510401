import { FieldProps, FormikProps } from 'formik';
import React from 'react';

import ChipInput, { ChipRendererArgs } from 'material-ui-chip-input';
import { useStyles } from './Themable.hoc';
import Chip from '@material-ui/core/Chip/Chip';
import { Tooltip } from '@material-ui/core';

interface IOwnProps {
    inputController: string;
    validateInput: (
        input: string,
        form: FormikProps<any>,
        fieldName: string,
        fieldValue: any,
        inputController: string
    ) => boolean;
    formatValue?: (value: string) => string;
}

type TProps = FieldProps & IOwnProps;

const ChipsFieldWrapper = ({
    field,
    form,
    inputController,
    validateInput,
    formatValue,
    ...props
}: TProps) => {
    const classes = useStyles();

    const renderChip = (
        { text, className, handleDelete, handleClick }: ChipRendererArgs,
        key: any
    ) => {
        const chip = (
            <Chip
                label={text}
                className={className}
                key={key}
                onDelete={handleDelete}
                onClick={handleClick}
            />
        );
        if (text.length > 53) {
            return (
                <Tooltip title={text} key={'tooltip' + key}>
                    {chip}
                </Tooltip>
            );
        }
        return chip;
    };

    const prepareValue = (value: string) => {
        if (formatValue) {
            return formatValue(value);
        }
        return value;
    };

    return (
        <ChipInput
            margin="dense"
            value={field.value}
            newChipKeyCodes={[9, 13, 32, 186, 188]}
            onBeforeAdd={(chip) =>
                validateInput(
                    chip,
                    form,
                    field.name,
                    field.value,
                    inputController
                )
            }
            onAdd={(chip) => {
                form.setFieldValue(field.name, [
                    ...field.value,
                    prepareValue(chip),
                ]);
                form.setFieldValue(inputController, '');
            }}
            onDelete={(chip, index) =>
                form.setFieldValue(
                    field.name,
                    field.value.filter((item: string, i: number) => i !== index)
                )
            }
            blurBehavior={'add'}
            onUpdateInput={(input) =>
                form.setFieldValue(inputController, input.target.value)
            }
            inputValue={form.values[inputController]}
            classes={{
                chipContainer: classes.chipInput,
                chip: classes.chip,
            }}
            chipRenderer={renderChip}
            {...props}
        />
    );
};
export default ChipsFieldWrapper;
