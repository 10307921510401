import React from 'react';

import { useMode } from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import PasswordEditionForm from './components/PasswordEditionForm';
import UserCreationForm from './components/UserCreationForm';
import UserEditionForm from './components/UserEditionForm';
import UserPreviewForm from './components/UserPreviewForm';

const modeSelector = {
    preview: () => <UserPreviewForm />,
    create: () => <UserCreationForm />,
    edit: () => <UserEditionForm />,
    editPassword: () => <PasswordEditionForm />,
};

const UserPane = () => {
    const mode = useMode();

    const classes = useStyles();

    return (
        <div className={classes.mainWrapper}>
            {mode && modeSelector[mode] && modeSelector[mode]()}
        </div>
    );
};

export default UserPane;
