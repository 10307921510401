import TranslationHelper from '../../../../../helpers/TranslationHelper';

export interface IFormUser {
    name: string;
    surname: string;
    email: string;
    roles: {
        [key: string]: boolean;
    };
}

interface IFormUserCreateOnly {
    login: string;
}

interface IFormUserPassword {
    password: string;
    passwordRepeat: string;
}

export type TFormPreviewUser = IFormUserCreateOnly & IFormUser;
export type TFormCreateUser = IFormUserCreateOnly &
    IFormUser &
    IFormUserPassword;
export type TFormEditUser = IFormUser;
export type TFormEditUserPassword = IFormUserPassword;

const userLoginFields = [
    {
        name: 'login',
        label: 'Login',
        autoComplete: 'username',
        required: true,
    },
];

const userLoginCreateFields = [
    {
        name: 'login',
        label: 'Login (e-mail format)',
        autoComplete: 'username',
        required: true,
    },
];
const userDetailsFields = [
    {
        name: 'name',
        label: 'First name',
        autoComplete: 'given-name',
        required: true,
    },
    {
        name: 'surname',
        label: 'Surname',
        autoComplete: 'family-name',
        required: true,
    },
    {
        name: 'email',
        label: 'E-mail',
        type: 'email',
        autoComplete: 'email',
        required: true,
    },
];

const userPasswordFields = [
    {
        name: 'password',
        label: 'Password',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
    },
    {
        name: 'passwordRepeat',
        label: 'Repeat password',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
    },
];

export const blankUser = {
    id: '',
    name: '',
    surname: '',
    email: '',
    roles: [],
};

export const fieldSets = {
    preview: [...userLoginFields, ...userDetailsFields],
    edit: userDetailsFields,
    create: [
        ...userLoginCreateFields,
        ...userDetailsFields,
        ...userPasswordFields,
    ],
    editPassword: userPasswordFields,
};

export const titles = {
    preview: () => TranslationHelper.translate('User details'),
    edit: () => TranslationHelper.translate('Editing user'),
    create: () => TranslationHelper.translate('Creating user'),
    editPassword: () => TranslationHelper.translate('Editing password'),
};
