import moment from 'moment';
import { IMultiIcon } from './types';

const generateNames = (params: IMultiIcon[]) =>
    params.map((icon) => icon.name).join(' ');

export default (args: any) => {
    const paramsData = args.params.data;
    const paramsColId = args.params.column.colId;
    if (args.type === 'multiIcon') {
        const rawValue = paramsData[paramsColId];
        return rawValue ? generateNames(rawValue as IMultiIcon[]) : '';
    } else if (args.type === 'date') {
        return args.params.getValue(paramsColId, paramsData)
            ? moment(
                  args.params.getValue(paramsColId, paramsData),
                  'DD.MM.YYYY'
              )
            : null;
    } else {
        return args.params.getValue(paramsColId, paramsData);
    }
};
