import UrlConst from '../../constants/UrlConst';

import { IPage, IRegisterFilter } from '../../state/app/registers/';
import { IDictionaryItem } from '../../state/app/dictionaries';
import { IEmployee } from '../../state/app/registers/employees';
import { IDevice } from '../../state/types';
import { ITank } from '../../state/app/registers/tanks';
import { IVehicle } from '../../state/app/registers/vehicles';
import { IRFIDCard } from '../../state/app/registers/rfidCards';
import { IRequestWithMessages } from '../../state/app/sync';
import { IRegistersMonitoredObject } from '../../state/app/registers/monitoredObjects';

import apiClient from '../common/apiClient';
import {
    makeEndpoint,
    makeGetRequestSettings,
    makePostMultipartRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
} from '../common/utils';
import { makeRestReadDevicesCollection } from '../devices';

export interface IImportRfidResponse {
    success: boolean;
    messages: string[];
}

export interface IGetMonitoredObjectsParams {
    name?: string;
    individualForUserId?: number;
}

export const makeEmployeesUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/employees' + suffix, {
        ...params,
    });
};

export const makeVehiclesUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/vehicles' + suffix, {
        ...params,
    });
};

export const makeFuelTanksUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/fuel-tanks' + suffix, {
        ...params,
    });
};

export const makeRfidCardsUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/rfid-cards' + suffix, {
        ...params,
    });
};

export const restGetRegistersMonitoredObjects = (
    params?: IGetMonitoredObjectsParams
): Promise<IRegistersMonitoredObject[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.REGISTERS_URL, '/monitored-objects', params)
        )
    );

export const restGetEmployees = (
    params: IRegisterFilter
): Promise<IPage<IEmployee>> =>
    apiClient.send(makeGetRequestSettings(makeEmployeesUrl('', params)));

export const restPostEmployee = (
    employee: IEmployee
): Promise<IRequestWithMessages> =>
    apiClient.send(makePostRequestSettings(makeEmployeesUrl(), employee));

export const restPutEmployee = (
    employee: IEmployee
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeEmployeesUrl(`/${employee.id}`), employee)
    );

export const restPutEmployeeCards = (
    id: string,
    cards: string[]
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeEmployeesUrl(`/${id}/cards`), cards)
    );

export const restGetVehicles = (
    params: IRegisterFilter
): Promise<IPage<IVehicle>> =>
    apiClient.send(makeGetRequestSettings(makeVehiclesUrl('', params)));

export const restPostVehicle = (
    vehicle: IVehicle
): Promise<IRequestWithMessages> =>
    apiClient.send(makePostRequestSettings(makeVehiclesUrl(), vehicle));

export const restPutVehicle = (
    vehicle: IVehicle
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeVehiclesUrl(`/${vehicle.id}`), vehicle)
    );

export const restPutVehicleCards = (
    id: string,
    cards: string[]
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeVehiclesUrl(`/${id}/cards`), cards)
    );

export const restGetTanks = (params: IRegisterFilter): Promise<IPage<ITank>> =>
    apiClient.send(makeGetRequestSettings(makeFuelTanksUrl('', params)));

export const restPutTank = (tank: ITank): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeFuelTanksUrl(`/${tank.id}`), tank)
    );

export const restPutFuelTankRfidCards = (
    id: string,
    cards: string[]
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeFuelTanksUrl(`/${id}/send-cards`), cards)
    );

export const restGetRfidCards = (
    params: IRegisterFilter
): Promise<IPage<IRFIDCard>> =>
    apiClient.send(makeGetRequestSettings(makeRfidCardsUrl('', params)));

export const restGetRfidCard = (id: string): Promise<IRequestWithMessages> =>
    apiClient.send(makeGetRequestSettings(makeRfidCardsUrl(`/${id}`)));

export const restPostRfidCard = (
    rfidCard: IRFIDCard
): Promise<IRequestWithMessages> =>
    apiClient.send(makePostRequestSettings(makeRfidCardsUrl(), rfidCard));

export const restPutRfidCard = (
    rfidCard: IRFIDCard
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeRfidCardsUrl(`/${rfidCard.id}`), rfidCard)
    );

export const restGetAvailableRfidCards = (): Promise<IRFIDCard[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeRfidCardsUrl('/all', { availableOnly: true })
        )
    );

export const restGetRfidCardsByGroup = (id: string): Promise<IRFIDCard[]> =>
    apiClient.send(
        makeGetRequestSettings(makeRfidCardsUrl('/all', { groupId: id }))
    );

export const restGetSentCards = (id: string): Promise<IDictionaryItem[]> =>
    apiClient.send(
        makeGetRequestSettings(makeFuelTanksUrl(`/${id}/send-cards`))
    );
export const restGetDictionary = (name: string): Promise<IDictionaryItem[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.DICTIONARIES_URL, `/${name}`)
        )
    );

export const restGetTimezoneDictionary = (): Promise<IDictionaryItem[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.DICTIONARIES_URL, '/timezones')
        )
    );

export const restGetDevices = (): Promise<IDevice[]> =>
    apiClient.send(
        makeGetRequestSettings(makeEndpoint(UrlConst.REGISTERS_URL, '/devices'))
    );

export const importRfid = (data: FormData): Promise<IImportRfidResponse> =>
    apiClient.send(
        makePostMultipartRequestSettings(
            makeEndpoint(UrlConst.REGISTERS_URL, '/imports'),
            data
        )
    );
export const restReadDevicesCollection =
    makeRestReadDevicesCollection(restGetDevices);
