import { FormikProps } from 'formik';

import TranslationHelper from './TranslationHelper';

export const validateEmail = (
    input: string,
    form: FormikProps<any>,
    fieldName: string,
    fieldValue: any,
    inputController: string
) => {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const servicesRegex = /^.+@.+\.[a-z0-9\-]{2,6}$/;
    const isDuplicate = fieldValue.includes(input);
    const inputValue = input.trim();

    if (isDuplicate) {
        form.setFieldError(
            fieldName,
            TranslationHelper.translate('Duplicate e-mail')
        );
        return false;
    }

    const emailParts = input.split('@');
    const isValidPartsLength =
        emailParts.length === 2 &&
        emailParts[0].length <= 64 &&
        emailParts[1].length <= 255;

    if (
        (inputValue.length <= 255 &&
            emailRegex.test(inputValue) &&
            servicesRegex.test(inputValue) &&
            isValidPartsLength) ||
        !inputValue
    ) {
        form.setFieldError(fieldName, '');
        form.setFieldError(inputController, '');
        return true;
    } else {
        const message =
            inputValue.length > 255
                ? TranslationHelper.translate('E-mail is too long')
                : TranslationHelper.translate('E-mail is invalid');
        form.setFieldError(fieldName, message);
        return false;
    }
};

export const preparePhoneNumber = (value: string) => {
    return value.replace(/\s/g, '').replace(/-/g, '');
};

export const validatePhoneNumber = (
    input: string,
    form: FormikProps<any>,
    fieldName: string,
    fieldValue: any,
    inputController: string
) => {
    const value = preparePhoneNumber(input);
    const phoneNumberRegex = /^(\+?[0-9]{1,3})?[0-9]{9}$/;
    const isDuplicate = fieldValue.includes(value);

    if (isDuplicate) {
        form.setFieldError(
            fieldName,
            TranslationHelper.translate('Duplicate phone number')
        );
        return false;
    }

    if (!value || phoneNumberRegex.test(value)) {
        form.setFieldError(fieldName, '');
        form.setFieldError(inputController, '');
        return true;
    } else {
        form.setFieldError(
            fieldName,
            TranslationHelper.translate('Phone number is invalid')
        );
        return false;
    }
};
