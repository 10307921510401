import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const spacing = theme.spacing(3);
    return createStyles({
        pane: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: spacing,
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        plusIconButton: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
