import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import UserPane from './components/UserPane';
import UsersGridPane from './components/UsersGridPane';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { Paper } from '@material-ui/core';
import hasAccess from '../../components/accessControl/hasAccess';

type TProps = TThemableProps & TConnectableProps;

class UsersPage extends Component<TProps> {
    public render() {
        const { userPaneIsVisible, classes } = this.props;

        const userPaneClass = userPaneIsVisible
            ? classes.visibleUserPane
            : classes.hiddenUserPane;

        return (
            <div className={classes.usersPageWrapper} id="usersPage">
                <div className={userPaneClass}>
                    <UsersGridPane />
                </div>

                {userPaneIsVisible && (
                    <Paper className={classes.userPaneWrapper}>
                        <UserPane />
                    </Paper>
                )}
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable,
    hasAccess((privileges) => privileges.usersPanel)
)(UsersPage) as ComponentType<{}>;
