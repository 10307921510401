import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import DeleteButton from '../../../../components/DeleteButton';
import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import { IActionHandlerParams, IActionValue } from '../../_utils/types';
import { ISourceSetEntity } from '../../../../state/types';
import { customAction } from '../../../../state/_actions';
import { useAppDispatch } from '../../../../hooks';
import { useStyles } from './Themable.hooks';

type TOwnProps = ICellRendererParams;

const ActionsButtons = (props: TOwnProps) => {
    const dispatch = useAppDispatch();

    const classes = useStyles();
    const handleDelete = (
        params: IActionHandlerParams,
        actionValue: IActionValue
    ) => {
        const action = { ...params.action, params: { id: params.id } };
        dispatch(
            customAction(action, undefined, () => actionValue.handler(params))
        );
    };
    const renderAction = (value: IActionValue, data: ISourceSetEntity) => {
        const action = (data._meta?.actions || {})[value.type];
        const handlerParams = {
            id: action.params.id?.toString(),
            action: value.action,
        };
        switch (value.type) {
            case 'preview':
                return (
                    <IconButtonWithTooltip
                        key={value.type}
                        title={TranslationHelper.translate('Details')}
                        onClick={() => value.handler(handlerParams)}
                        size="small"
                        defaultColor
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButtonWithTooltip>
                );
            case 'edit':
                return (
                    <IconButtonWithTooltip
                        key={value.type}
                        title={TranslationHelper.translate('Edit')}
                        onClick={() => value.handler(handlerParams)}
                        size="small"
                        defaultColor
                    >
                        <EditIcon fontSize="small" />
                    </IconButtonWithTooltip>
                );
            case 'delete':
                return (
                    <DeleteButton
                        key={value.type}
                        title="Removal confirmation"
                        tooltip="Delete"
                        message="Are you sure?"
                        deleteMethod={() => handleDelete(handlerParams, value)}
                        size="small"
                        defaultColor
                    />
                );
            default:
                return <></>;
        }
    };

    const actions = props.value as IActionValue[];

    return actions.length ? (
        <div className={classes.actions}>
            {actions.map((action) => renderAction(action, props.data))}
        </div>
    ) : (
        ''
    );
};

export default ActionsButtons;
