import {
    IRestPostGroup,
    IRestPutGroup,
    restPostGroup,
    restPutGroup,
    restReadGroupsCollection,
} from '../../../services/groups';
import {
    IRestPostUser,
    IRestPutUser,
    IRestPutUserPassword,
    restDeleteUser,
    restPostUser,
    restPutUser,
    restPutUserPassword,
    restReadRolesCollection,
    restReadUsersCollection,
} from '../../../services/users';

import { deselectUser, resetMode } from '../../ui/usersSlice';
import { makeRequest } from '../sync';
import { IGroup, IRole, IUser, TOptionalNormalizedData } from './dataTypes';
import { duck } from './state';

export const setUsers = duck.defineAction<{
    collection: TOptionalNormalizedData<IUser>;
}>('SET_USERS', (state, { collection }) => ({
    ...state,
    users: collection,
}));

export const setRoles = duck.defineAction<{
    collection: TOptionalNormalizedData<IRole>;
}>('SET_ROLES', (state, { collection }) => ({
    ...state,
    roles: collection,
}));

export const setGroups = duck.defineAction<{
    collection: TOptionalNormalizedData<IGroup>;
}>('SET_GROUPS', (state, { collection }) => ({
    ...state,
    groups: collection,
}));

export default duck.getReducer();

export const getRoles = () =>
    makeRequest(
        `get:app/collections/roles`,
        () => restReadRolesCollection(),
        (dispatch, data) => dispatch(setRoles({ collection: data })),
        (dispatch, error) => undefined
    );

export const getUsers = () =>
    makeRequest(
        `get:app/collections/users`,
        () => restReadUsersCollection(),
        (dispatch, data) => dispatch(setUsers({ collection: data })),
        (dispatch, error) => undefined
    );

const groupsStorePath = 'get:app/collections/groups';

export const getGroups = () =>
    makeRequest(
        groupsStorePath,
        () => restReadGroupsCollection(),
        (dispatch, data) => dispatch(setGroups({ collection: data })),
        (dispatch, error) => undefined
    );

export const createUserStorePath = 'post:app/collections/users';

export const createUser = (user: IRestPostUser, afterRequest: () => void) =>
    makeRequest(
        createUserStorePath,
        () => restPostUser(user),
        (dispatch, data) => {
            dispatch(resetMode());
            // @ts-ignore
            dispatch(getUsers());
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (afterRequest) {
                afterRequest();
            }
        }
    );

export const getUpdateUserStorePath = (userId: number) =>
    `put:app/collections/users/${userId}`;

export const updateUser = (user: IRestPutUser) =>
    makeRequest(
        getUpdateUserStorePath(user.id),
        () => restPutUser(user),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(getUsers());
        },
        (dispatch, error) => undefined
    );

export const updatePasswordStorePath = `put:app/collections/users/password`;

export const updatePassword = (value: IRestPutUserPassword) =>
    makeRequest(
        updatePasswordStorePath,
        () => restPutUserPassword(value),
        (dispatch, data) => undefined,
        (dispatch, error) => undefined
    );

export const getDeleteUserStorePath = (userId: number) =>
    `delete:app/collections/users/${userId}`;

export const deleteUser = (userId: number) =>
    makeRequest(
        getDeleteUserStorePath(userId),
        () => restDeleteUser(userId),
        (dispatch, data) => {
            dispatch(deselectUser());
            // @ts-ignore
            dispatch(getUsers());
        },
        (dispatch, error) => undefined
    );

export const createGroupStorePath = 'post:app/collections/groups';

export const createGroup = (
    group: IRestPostGroup,
    afterRequest?: (data: any) => void
) =>
    makeRequest(
        createGroupStorePath,
        () => restPostGroup(group),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(getGroups());
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterRequest) {
                afterRequest(error);
            }
        }
    );

export const getUpdateGroupStorePath = (groupId: string | null) =>
    `put:app/collections/groups/${groupId}`;

export const updateGroup = (
    group: IRestPutGroup,
    afterRequest?: (data: any) => void
) =>
    makeRequest(
        getUpdateGroupStorePath(group.id),
        () => restPutGroup(group),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(getGroups());
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterRequest) {
                afterRequest(error);
            }
        }
    );
