import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        root: {
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            textAlign: 'center',
            fontSize: theme.typography.h6.fontSize,
            paddingBottom: '15px',
        },
        subtitle: {},
        formField: {
            margin: '10px 0',
        },
        loginForm: {
            maxWidth: '378px',
            margin: '100px auto',
            padding: '20px',
            border: '1px solid #D1D1D1',
            borderRadius: '3px',
            backgroundColor: 'white',

            [theme.breakpoints.down('xs')]: {
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                margin: 0,
                maxWidth: 'none',
            },
        },

        formButtons: {
            display: 'flex',
            margin: '10px 0',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& a': {
                textDecoration: 'none',
            },
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
