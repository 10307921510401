import moment from 'moment';
import { IMultiIcon } from './types';

interface IDateComparator {
    dateFormat: string;
    first: string;
    second: string;
}

export const dateComparator = ({
    dateFormat,
    first,
    second,
}: IDateComparator) => {
    if (first === '' && second === '') {
        return 0;
    }
    if (first === '') {
        return -1;
    }
    if (second === '') {
        return 1;
    }
    const firstDateMoment = moment(first, dateFormat);
    const secondDateMoment = moment(second, dateFormat);

    return firstDateMoment.diff(secondDateMoment);
};

interface INumberComparator {
    first?: number;
    second?: number;
}

const number = ({ first, second }: INumberComparator) => {
    if (first === undefined && second === undefined) {
        return 0;
    }
    if (first === undefined) {
        return -1;
    }
    if (second === undefined) {
        return 1;
    }

    return first - second;
};

interface IMultiIconComparator {
    first?: IMultiIcon[];
    second?: IMultiIcon[];
}

const multiIcon = ({ first, second }: IMultiIconComparator) => {
    if (first === undefined && second === undefined) {
        return 0;
    }
    if (!first) {
        return -1;
    }
    if (!second) {
        return 1;
    }

    if (first.length > second.length) {
        return 1;
    }
    if (first.length < second.length) {
        return -1;
    }

    for (let i = 0; i < first.length; i++) {
        const compareResult = first[i].name.localeCompare(second[i].name);
        if (compareResult !== 0) {
            return compareResult;
        }
    }

    return 0;
};

const comparatorTypes = {
    date: dateComparator,
    multiIcon,
    number,
};

const defaultComparator = (first?: string, second?: string) => {
    const isFirstEmpty = first === '' || first === undefined || first === null;
    const isSecondEmpty =
        second === '' || second === undefined || second === null;
    if (isFirstEmpty && isSecondEmpty) {
        return 0;
    }
    if (isFirstEmpty) {
        return -1;
    }
    if (isSecondEmpty) {
        return 1;
    }

    const lowerFirst = String(first)?.toLowerCase() || '';
    const lowerSecond = String(second)?.toLowerCase() || '';
    return lowerFirst.localeCompare(lowerSecond);
};

interface IComparatorArgs {
    dateFormat: string;
    type: string;
}

export default ({ dateFormat, type }: IComparatorArgs) =>
    (first: any, second: any) => {
        return comparatorTypes[type]
            ? comparatorTypes[type]({ dateFormat, first, second })
            : defaultComparator(first, second);
    };
