import { connect } from 'react-redux';
import { enterCreateMode } from '../../state/ui/usersSlice';
import { TRootState } from '../../store';
import { getUserPaneIsVisible } from './selectors';

const mapStateToProps = (state: TRootState) => ({
    userPaneIsVisible: getUserPaneIsVisible(state),
});

const mapDispatchToProps = { enterCreateMode };

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
