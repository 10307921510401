import React from 'react';

import { Form, Formik, Field } from 'formik';

import { useUser } from '../../Connectable.hooks';
import { useStyles } from '../../Themable.hooks';

import { goToPreviewMode } from '../../../../../../state/ui/usersSlice';
import { updatePassword } from '../../../../../../state/app/collections';

import { useAppDispatch } from '../../../../../../hooks';

import { passwordSchema } from '../../../../../../schemas';
import { fieldSets, TFormEditUserPassword, titles } from '../../settings';
import Fields from '../Fields';
import UserPaneHeader from '../UserPaneHeader';

const blankFormPassword: TFormEditUserPassword = {
    password: '',
    passwordRepeat: '',
};

const PasswordEditionForm = () => {
    const user = useUser();

    const classes = useStyles();

    const dispatch = useAppDispatch();

    const initialValues = blankFormPassword;

    const handleCloseClick = () => {
        dispatch(goToPreviewMode());
    };

    const handleValues = ({
        password,
        passwordRepeat,
    }: TFormEditUserPassword) => {
        if (user === null) {
            return;
        }

        dispatch(
            updatePassword({
                userId: user.id,
                newPassword: password,
            })
        );
        handleCloseClick();
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={passwordSchema()}
        >
            {({ submitForm, errors }) => (
                <>
                    <UserPaneHeader
                        title={titles.editPassword()}
                        onClose={handleCloseClick}
                        onSave={submitForm}
                    />

                    <div className={classes.paddingWrapper}>
                        <Form>
                            <Field
                                key={'username'}
                                name={'username'}
                                autoComplete={'username'}
                                value={user?.login}
                                disabled
                                style={{ display: 'none' }}
                            />
                            <Fields
                                fields={fieldSets.editPassword}
                                errors={errors}
                            />
                        </Form>
                    </div>
                </>
            )}
        </Formik>
    );
};

export default PasswordEditionForm;
