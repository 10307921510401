import React, { ComponentType, useState, useEffect } from 'react';

import { compose } from 'redux';

import { default as PlusIcon } from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { LinearProgress } from '@material-ui/core';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import PaneHeader from '../../../../components/PaneHeader';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { ISourceSetEntity } from '../../../../state/types';
import SourceSetGrid from '../../../../components/SourceSetGrid';
import { AccessibleIconButton } from '../../../../components/accessControl/components';
import SearchField from '../../../../components/common/SearchField/SearchField';

type TProps = TThemableProps & TConnectableProps;

const UsersGridPane = ({
    classes,
    rolesData,
    selectedSourceSetElementId,
    sourceSet,
    sourceSetModel,
    enterCreateMode,
    getRoles,
    getUsers,
    selectUser,
}: TProps) => {
    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');

    useEffect(() => {
        getUsers();
        getRoles();

        if (sourceSetModel) {
            handleFilterChange(sourceSetModel.quickFilter);
        } else {
            handleFilterChange('');
        }
    }, []);

    const handleFilterChange = (value: string) => {
        setFilter(value);
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        selectUser(data.id);
    };

    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Users')}
                    renderRightCustomControls={() => (
                        <AccessibleIconButton
                            check={(privileges) => privileges.addUser}
                            tooltipTitle="Add user"
                            onClick={() => enterCreateMode()}
                            className={classes.plusIconButton}
                            aria-label="Create"
                        >
                            <PlusIcon />
                        </AccessibleIconButton>
                    )}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={handleFilterChange}
                            />
                        </>
                    )}
                />
                {!sourceSet && <LinearProgress />}
                {sourceSet && rolesData && (
                    <SourceSetGrid
                        sourceSet={sourceSet}
                        customData={{ roles: rolesData }}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={selectedSourceSetElementId}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                    />
                )}
            </div>
        </Paper>
    );
};

export default compose(
    Themable,
    Connectable
)(UsersGridPane) as ComponentType<{}>;
