import TimeFormatter from '../../../helpers/TimeFormatter';
import TranslationHelper from '../../../helpers/TranslationHelper';

import { IActionValue, IValueGettersParams } from './types';
import format from 'string-template';

export default (params: IValueGettersParams) => {
    if (params.type === 'roles' && !!params.customData) {
        return (
            params.value &&
            (params.value as number[])
                .map(
                    (id) =>
                        (params.customData &&
                            params.customData[id] &&
                            params.customData[id].name) ||
                        ''
                )
                .join(', ')
        );
    } else if (params.type === 'date' && params.userSettings) {
        if (params.formatting && params.formatting.pattern === 'short') {
            return params.value
                ? TimeFormatter.dateToString(
                      params.value as string,
                      params.userSettings.shortDateFormat
                  )
                : '';
        } else {
            return params.value
                ? TimeFormatter.dateToString(
                      params.value as string,
                      params.userSettings.dateFormat
                  )
                : '';
        }
    } else if (params.type === 'number') {
        if (
            params.formatting &&
            params.formatting.decimalPlaces !== undefined
        ) {
            return params.value !== undefined && params.value !== null
                ? Number(
                      (params.value as number).toFixed(
                          params.formatting.decimalPlaces
                      )
                  )
                : undefined;
        } else {
            return params.value;
        }
    } else if (params.type === 'icon' || params.type === 'binary') {
        return params.value ? (params.value as { name: string }).name : '';
    } else if (params.type === 'duration') {
        return TimeFormatter.formatDuration(params.value as number);
    } else if (params.type === 'list') {
        return params.value != undefined
            ? (params.value as any[])
                  .map(
                      (item) =>
                          (params.formatting?.pattern &&
                              format(params.formatting.pattern, item)) ||
                          item
                  )
                  .join(params.formatting?.separator ?? ', ')
            : undefined;
    } else if (params.type === 'tooltipList') {
        return params.value != undefined
            ? (params.value as any[]).map(
                  (item) =>
                      (params.formatting?.pattern &&
                          format(params.formatting.pattern, item)) ||
                      item
              )
            : undefined;
    } else if (params.type === 'actions') {
        const sourceSetActions = params.sourceSet?._meta.actions || {};
        const actionValue: IActionValue[] = ((params.value as string[]) ?? [])
            .filter((action) => params.actionHandlers?.[action] !== undefined)
            .map((action) => ({
                type: action,
                action: sourceSetActions[action],
                handler: params.actionHandlers![action],
            }));
        return actionValue;
    } else if (params.type === 'bar') {
        return [params.value];
    } else if (params.translate !== undefined) {
        return TranslationHelper.translate(
            params.value as string,
            params.translate
        );
    } else {
        return params.value;
    }
};
