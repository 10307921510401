import { useSelector } from 'react-redux';

import { getTab } from './selectors';

import {
    getRoleCreationRequest,
    getRoleDeleteRequest,
    getRoleUpdateRequest,
} from '../roles/selectors';

import {
    getUserCreationRequest,
    getUserUpdateRequest,
    getUserDeleteRequest,
    getPasswordUpdateRequest,
} from '../users/selectors';
import { getPrivileges } from '../discovery/selectors';

export const useTab = () => useSelector(getTab);
export const useUserPrivileges = () => useSelector(getPrivileges);
export const useRoleCreateRequest = () => useSelector(getRoleCreationRequest);
export const useRoleUpdateRequest = () => useSelector(getRoleUpdateRequest);
export const useRoleDeleteRequest = () => useSelector(getRoleDeleteRequest);
export const useUserCreateRequest = () => useSelector(getUserCreationRequest);
export const useUserUpdateRequest = () => useSelector(getUserUpdateRequest);
export const useUserDeleteRequest = () => useSelector(getUserDeleteRequest);
export const useUserPasswordUpdateRequest = () =>
    useSelector(getPasswordUpdateRequest);
